"use client";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import z from "zod";
import PhoneInput from "react-phone-input-2";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export interface ContactBlockCustomProps {
  color?: "green" | "blue" | "violet";
  btnTag?: string;
  socialLinks?: {
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    linkedin?: string;
    youtube?: string;
  };
  link?: string;
  txt?: string;
  txt_form?: string;
}

type FormFields = {
  fields_1: string;
  fields_2: string;
  phoneNumber: string;
  date?: string;
};

// Схема валідації
const formSchema = z.object({
  fields_1: z.string().min(1, "Ім'я є обов'язковим"),
  fields_2: z.string().min(1, "Прізвище є обов'язковим"),
  phoneNumber: z
    .string()
    .min(1, "Телефон є обов'язковим")
    .regex(/^\+?[0-9]{10,14}$/, "Невірний формат номера телефону"),
  date: z.string().optional(),
});

export default function ContactBlockCustom({
  color = "green",
  btnTag,
  socialLinks,
  txt = `Незалежно від вашого віку, місця проживання чи мети, AP
  Education допоможе вам досягти своїх освітніх і професійних
  мрій!`,
  txt_form = "Залишайте заявку, щоб отримати детальну інформацію",
  link = "https://ap-server-8qi1.onrender.com/leads",
}: ContactBlockCustomProps) {
  const [formData, setFormData] = useState<FormFields>({
    fields_1: "",
    fields_2: "",
    phoneNumber: "",
    date: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<FormFields | null>(null);
  const router = useRouter();
  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn",
      blue: "custom-gradient-blue-btn",
      violet: "custom-gradient-violet-btn",
    }[color] || "custom-gradient-green-btn";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    setError(null);
    setFormErrors(null);
    const utmTags = JSON.parse(localStorage.getItem("utmTags") || "{}") || {};

    try {
      const validatedData = formSchema.parse(formData);
      await axios.post(link, {
        name: `${validatedData.fields_1} ${validatedData.fields_2}`,
        phone: validatedData.phoneNumber,
        time: validatedData.date,
        tag: btnTag,
        ...utmTags,
      });

      setSuccess(true);
      router.push("/thankyou");
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: FormFields = {
          fields_1: "",
          fields_2: "",
          phoneNumber: "",
        };

        error.issues.forEach((err) => {
          const fieldName = err.path[0] as keyof FormFields;
          newErrors[fieldName] = err.message;
        });

        setFormErrors(newErrors);
        setError(
          newErrors.fields_1 || newErrors.fields_2 || newErrors.phoneNumber
        );
      } else {
        setError("Форма не відправлена. Спробуйте, будь ласка, ще раз.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = window.document.location.hash.slice(
      1,
      window.document.location.hash.length
    );
    if ("contact_from" === id) {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handlePhoneChange = (value: string) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
    setFormErrors({
      ...formErrors,
      phoneNumber: "",
      fields_1: formErrors?.fields_1 || "",
      fields_2: formErrors?.fields_2 || "",
    });
  };

  return (
    <div id="contact_from" data-meta="ContactBlockCustom" className="relative">
      <div className="relative mx-auto sm:container isolate sm:p-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 max-w-7xl mx-auto rounded-3xl directory-big max-sm:min-h-[850px]">
          <div className="relative px-6 pb-8 max-lg:mt-12 lg:pb-52 pt-34 sm:pt-24 lg:pt-52 lg:static lg:px-8 lg:col-span-6">
            <div className="mx-auto lg:pl-8 lg:max-w-2xl lg:mx-0 max-w-lg">
              <p className="text-3xl flex-1 lg:text-5xl font-bold tracking-tight text-gray-900">
                Станьте частиною нашого освітнього ком’юніті!{" "}
                <span>
                  <Image
                    src={"/assets/icon/emoji/Rocket.svg"}
                    width={40}
                    height={40}
                    alt={""}
                    className="inline-block"
                  />
                </span>
              </p>
              <p className="mt-6 sm:text-xs md:text-lg lg:text-xl leading-2 lg:leading-8 text-gray-600">
                {txt}
              </p>
              <div className="flex gap-3 mt-8">
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 ${backgroundColorClass} rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.facebook ??
                      "https://www.facebook.com/ap.edu.centre/"
                    }
                    target="_blank"
                  >
                    <Image
                      width={64}
                      height={64}
                      className="h-12 w-12 md:h-16 md:w-16 p-2 md:p-4"
                      src="/assets/icon/FBSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 ${backgroundColorClass} rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.tiktok ??
                      "https://www.tiktok.com/@ap.education.center?_t=8qLAl2a7AUH&_r=1"
                    }
                    target="_blank"
                  >
                    <Image
                      width={64}
                      height={64}
                      className="h-12 w-12 md:h-16 md:w-16 p-2 md:p-4"
                      src="/assets/icon/TKSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 ${backgroundColorClass} rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.instagram ??
                      "https://www.instagram.com/ap.education"
                    }
                    target="_blank"
                  >
                    <Image
                      width={64}
                      height={64}
                      className="h-12 w-12 md:h-16 md:w-16 p-2 md:p-4"
                      src="/assets/icon/InstSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 ${backgroundColorClass} rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.linkedin ??
                      "https://www.linkedin.com/company/ap-education-center"
                    }
                    target="_blank"
                  >
                    <Image
                      width={64}
                      height={64}
                      className="h-12 w-12 md:h-16 md:w-16 p-2 md:p-4"
                      src="/assets/icon/LinkSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
                <div
                  className={`h-fit space-y-4 text-base leading-7 text-gray-600 ${backgroundColorClass} rounded-full flex justify-center items-center`}
                >
                  <Link
                    href={
                      socialLinks?.youtube ??
                      "https://www.youtube.com/@ap.education.center"
                    }
                    target="_blank"
                  >
                    <Image
                      width={64}
                      height={64}
                      className="h-12 w-12 md:h-16 md:w-16 p-2 md:p-4"
                      src="/assets/icon/YTSolid.svg"
                      alt=" "
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="px-6 mt:mb-4 sm:pb-8 pr-8 lg:px-8 lg:pt-52  lg:pb-52 lg:col-start-7 lg:col-span-6"
          >
            <div className="mx-auto lg:max-w-2xl max-w-lg lg:mr-0 rounded-3xl bg-gray-100">
              <div className="p-10 xl:mr-8">
                <p className="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 mb-4">
                  {txt_form}
                </p>
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label htmlFor="txt-name" className="sr-only">
                      Імя
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="fields_1"
                        autoComplete="off"
                        name="fields_1"
                        type="text"
                        placeholder="Ім'я*"
                        value={formData.fields_1}
                        onChange={handleInputChange}
                        className={`block w-full px-3.5 py-2 text-gray-900 shadow-sm border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="sr-only">
                      Прізвище
                    </label>
                    <div className="mt-2.5">
                      <input
                        autoComplete="off"
                        id="last-name"
                        name="fields_2"
                        type="text"
                        placeholder="Прізвище*"
                        value={formData.fields_2}
                        onChange={handleInputChange}
                        className={`block w-full px-3.5 py-2 text-gray-900 shadow-sm border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="phone-contact-v1" className="sr-only">
                      Телефон
                    </label>
                    <div className="mt-2.5">
                      <PhoneInput
                        country={"ua"}
                        excludeCountries={["ru"]}
                        value={formData.phoneNumber}
                        placeholder="Телефон"
                        onChange={handlePhoneChange}
                        containerClass={`mt-1 block w-full`}
                        inputClass={`w-full px-3 py-2 rounded-full   border  border-gray-300  sm:text-sm focus:shadow-md focus:border-gray-300 focus:ring-0`}
                        buttonClass="focus:rounded-full hover:bg-inherit focus:bg-inherit bg-inherit border-l-0 border-gray-300"
                        inputProps={{
                          name: "phone",
                          id: "phone-contact-v1",
                          required: true,
                        }}
                        containerStyle={{
                          borderRadius: "2rem",
                        }}
                        inputStyle={{
                          width: "100%",
                          height: "2.25rem",
                          fontSize: "0.875rem",
                          backgroundColor: "#fff",
                          borderRadius: "2rem",
                        }}
                        buttonStyle={{
                          backgroundColor: "transparent",
                          borderRadius: "2rem 0 0 2rem",
                          borderWidth: "1px",
                          borderColor: "#e2e8f0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="date" className="sr-only">
                      Коли вам зателефонувати?
                    </label>
                    <input
                      autoComplete="off"
                      id="date"
                      name="date"
                      type="text"
                      placeholder="Коли вам зателефонувати?"
                      value={formData.date}
                      onChange={handleInputChange}
                      className={`block w-full px-3.5 py-2 text-gray-900 shadow-sm border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0 sm:text-sm sm:leading-6`}
                    />
                  </div>
                </div>
                <div className="mt-8 flex max-sm:flex-col justify-start">
                  <button
                    type="submit"
                    disabled={loading}
                    className={`${backgroundColorClass} uppercase rounded-full px-8 py-2.5 text-center text-md font-fixel font-bold text-white shadow-sm focus-visible:outline`}
                  >
                    {loading ? "Надсилання..." : "Надіслати"}
                  </button>
                  {error && (
                    <p className="mt-2 ml-2 text-red-500 text-sm">{error}</p>
                  )}
                  {success && (
                    <p className="mt-2 ml-2 text-green-500 text-sm">
                      Форма успішно надіслана!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
